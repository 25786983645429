export const getFeatureNameStorageParam = (pathname: string) => {
  switch (true) {
    case pathname.includes('reverse-number-lookup'):
      return 'phoneNumberLookup';
    case pathname.includes('find-my-lost-phone'):
      return 'lostPhone';
    case pathname.includes('reverse-username-lookup'):
      return 'isUsernameLookup';
    case pathname.includes('ip-tracker'):
      return 'locationByLink';
    default:
      return '';
  }
};
