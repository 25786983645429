import { type FC, useState, useRef, useEffect } from 'react';
import styles from './FAQItem.module.scss';
import Typography from '#components/ui/Typography';
import Caret from '#assets/icons/Caret';

type Props = {
  question: string;
  answers: string[];
  initialState: boolean;
  shortItems?: boolean;
};

const FAQItem: FC<Props> = ({ answers, question, initialState, shortItems }) => {
  const content = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<boolean>(initialState);
  const [height, setHeight] = useState(0);

  const withOpenClass = (classes: string) => {
    return `${open ? styles.open : ''} ${classes}`;
  };

  useEffect(() => {
    const { scrollHeight } = content.current as HTMLDivElement;
    setHeight(open ? scrollHeight : 0);
  }, [open]);

  return (
    <div className={withOpenClass(styles.item)}>
      <div
        className={withOpenClass(styles.head)}
        onClick={() => setOpen((prev) => !prev)}
        style={shortItems ? { padding: '16px' } : {}}
        role="presentation"
      >
        <Typography
          component="h3"
          variant="subtitle1"
          className={withOpenClass(styles.question)}
          itemScope
          itemProp="mainEntity"
          itemType="https://schema.org/Question"
        >
          {question}
        </Typography>
        <span className={withOpenClass(styles.icon)}>
          <Caret />
        </span>
      </div>
      <div
        className={withOpenClass(styles.details)}
        ref={content}
        style={{ maxHeight: `${height}px` }}
      >
        {answers.map((answer, idx) => (
          <Typography
            key={idx}
            component="p"
            variant="body1"
            color="secondary"
            itemScope
            itemProp="acceptedAnswer"
            itemType="https://schema.org/Answer"
          >
            {answer}
          </Typography>
        ))}
        {/* required for animation */}
        <p style={{ height: '12px' }}></p>
      </div>
    </div>
  );
};

export default FAQItem;
