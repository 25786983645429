import i18next from 'i18next';

import styles from './LanguageSelector.module.scss';

import { prepareUrl } from '#utils/prepareUrl';
import { LANGS, LOST_PHONE_LANGS, NUMBER_LOOKUP_LANGS, USERNAME_LOOKUP_LANGS } from '#config';
import { localeLanguageMap } from '#constants/locales';
import { useMemo } from 'react';

const LanguageSelector = () => {
  const currentLanguage = i18next.language;

  const changeLanguage = (lng: string) => () => {
    if (typeof document === 'undefined') return '';
    i18next.changeLanguage(lng).then(() => (document.location.href = prepareUrl(null, lng)));
  };

  const getLocaleTitle = (lng: string) => {
    return localeLanguageMap[lng];
  };

  const checkIsActive = (lng: string) => lng === currentLanguage;

  const handleActive = (active: boolean, className: string) =>
    `${className} ${active ? styles.active : ''}`;

  const langs = useMemo(() => {
    const path = document.location.href;
    switch (true) {
      case path.includes('find-my-lost-phone'):
        return LOST_PHONE_LANGS;
      case path.includes('reverse-number-lookup'):
        return NUMBER_LOOKUP_LANGS;
      case path.includes('reverse-username-lookup'):
        return USERNAME_LOOKUP_LANGS;
      default:
        return LANGS;
    }
  }, [document.location.href]);

  return (
    <ul className={`${styles.menuItems}`}>
      {langs.map((lng: string) => (
        <li
          onClick={changeLanguage(lng)}
          className={handleActive(checkIsActive(lng), styles.menuItem)}
          key={`${lng}_lang`}
        >
          <a href={prepareUrl(null, lng)}>{getLocaleTitle(lng)}</a>
        </li>
      ))}
    </ul>
  );
};

export default LanguageSelector;
