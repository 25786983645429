import 'swiper/swiper-bundle.css';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { useRef, useState, useEffect, useCallback } from 'react';
import styles from './DemoSwiper.module.scss';
import Demo1 from '#images/main-page/demo1.png';
import Demo2 from '#images/main-page/demo2.png';
import Demo3 from '#images/main-page/demo3.png';
import Demo4 from '#images/main-page/demo4.png';
import Demo5 from '#images/main-page/demo5.png';
import Typography from '#components/ui/Typography';
import { Swiper as SwiperType } from 'swiper/types';

const images = [Demo1, Demo2, Demo3, Demo4, Demo5];
const titlesIcons = [
  styles.location,
  styles.lookup,
  styles.checker,
  styles.linkDetect,
  styles.phoneDetect,
];

type Props = {
  content: { title: string; text: string; alt: string }[];
};

const Slider = ({ content }: Props) => {
  const [activeDemo, setActiveDemo] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(activeDemo);
    }
  }, [activeDemo, swiperRef]);

  const renderItem = useCallback(
    (index: number, icon: string) => {
      return (
        <div
          className={
            activeDemo === index ? `${styles.item} ${styles.itemActive}` : `${styles.item}`
          }
          key={index}
        >
          <div className={styles.title} onClick={() => setActiveDemo(index)} role="presentation">
            <div className={icon} />
            <Typography component="span" variant="subtitle1">
              {content[index].title}
            </Typography>
          </div>

          <Typography component="p" variant="body2" color="primary">
            {content[index].text}
          </Typography>
        </div>
      );
    },
    [activeDemo],
  );

  return (
    <div className={styles.demo}>
      <div className={styles.pagination}>{titlesIcons.map((i, index) => renderItem(index, i))}</div>
      <Swiper
        className={styles.slider}
        initialSlide={activeDemo}
        slidesPerView={1}
        loop={false}
        ref={swiperRef}
        onSlideChange={(swiper: SwiperType) => setActiveDemo(swiper.activeIndex)}
      >
        {images.map((img, index) => (
          <SwiperSlide className={styles.slide} key={index}>
            <img src={img.src} alt={content[index].alt} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
